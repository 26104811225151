import { useUserStore } from '@/features/authentication';
import { CustomError } from '@/features/common/models';
import { useMainStore } from '@/features/common/stores';
import { useDeparturesSearchStore } from '@/features/departures/stores';
import { useTripSearchStore } from '@/features/trips/stores';
import { useContainer } from '@/plugins/inversify';
import { IBookingService, IBookingServiceId } from '@/services';
import { BookingDto, BookingStatus, CreateBookingDto, StopSignalOrderDto } from '@/types/webapi';
import { isAxiosError } from 'axios';
import { defineStore } from 'pinia';
import { computed, ref, toValue } from 'vue';
import { UserInfo } from '../models';
import { isAfter, isBefore, isEqual } from 'date-fns';
import { isDate } from 'lodash-es';
import { BookingParams, createBookingParams } from '../functions';

export const defaultUserInfo = (): UserInfo => ({
    phone: {
        countryCode: '+47',
        number: ''
    }
});

export const useBookingStore = defineStore('bookingStore', () => {
    const userStore = useUserStore();
    const mainStore = useMainStore();
    const tripSearchStore = useTripSearchStore();
    const departureSearchStore = useDeparturesSearchStore();

    const container = useContainer();
    const bookingService = container.get<IBookingService>(IBookingServiceId);

    const bookingIsEnabled = computed(() => mainStore.config.bookingConfig?.enabled);
    const maxBookingExceeded = ref(false);
    const isLoading = ref(false);
    const isBooking = ref(false);
    const bookings = ref<BookingDto[]>([]);
    const selectedBooking = ref<BookingDto>();
    const stopSignalOrder = ref<StopSignalOrderDto>();
    const createBookingProps = computed<BookingParams | undefined>(() => {
        if (!bookingIsEnabled.value) return undefined;

        if (departureSearchStore.selectedDeparture) {
            return createBookingParams(departureSearchStore.selectedDeparture);
        }

        if (tripSearchStore.selectedTripPattern) {
            // generate params from first leg where onlineBookingIsAvailable=true
            return createBookingParams(tripSearchStore.selectedTripPattern.legs.find(x => x.bookingMetadata?.onlineBookingIsAvailable));
        }

        return undefined;
    });
    const onlineBookingIsAvailable = computed(() => !!createBookingProps.value);
    const bookingDeadlineExceeded = computed(
        () =>
            isDate(createBookingProps.value?.bookingLimitDateTime) &&
            isAfter(mainStore.currentTime, createBookingProps.value.bookingLimitDateTime)
    );
    const canBook = computed(() => !!createBookingProps.value && !bookingDeadlineExceeded.value && !selectedBooking.value);
    const canCancel = computed(
        () =>
            selectedBooking.value?.isCancellationAllowed &&
            selectedBooking.value.status !== BookingStatus.Cancelled &&
            (!isDate(createBookingProps.value?.bookingCancellationLimitDateTime) ||
                isBefore(mainStore.currentTime, createBookingProps.value.bookingCancellationLimitDateTime))
    );
    const userInfo = ref<UserInfo>(defaultUserInfo());
    const userInfoIsValid = computed(() => userStore.isLoggedIn || !!(userInfo.value.phone?.countryCode && userInfo.value.phone.number));

    let loadStopSignalOrderAbortController: AbortController | undefined;
    let loadBookingsAbortController: AbortController | undefined;

    async function loadStopSignalOrder() {
        stopSignalOrder.value = undefined;

        // abort previous request
        loadStopSignalOrderAbortController?.abort();
        loadStopSignalOrderAbortController = new AbortController();

        if (!createBookingProps.value) return;

        try {
            isLoading.value = true;

            stopSignalOrder.value = await bookingService.getStopSignalOrder(createBookingProps.value, {
                signal: loadStopSignalOrderAbortController.signal
            });

            isLoading.value = false;
        } catch (e) {
            if (isAxiosError(e)) {
                // in case of AbortController being triggered, do nothing
                if (e.name === 'CanceledError') return;

                if (e.response?.status !== 401) {
                    mainStore.registerError(new CustomError('BookingError', e.response?.data || ''));
                }
            } else {
                console.error(e);
            }

            isLoading.value = false;
        }
    }

    async function loadBookings() {
        selectedBooking.value = undefined;

        // abort previous request
        loadBookingsAbortController?.abort();
        loadBookingsAbortController = new AbortController();

        if (!userStore.isLoggedIn) return;

        try {
            isLoading.value = true;

            // get all user bookings
            bookings.value = await bookingService.getAllBookings({ signal: loadBookingsAbortController.signal });
            maxBookingExceeded.value = bookings.value.length > 2;

            // set matching booking as selectedBooking
            selectedBooking.value = bookings.value.find(
                x =>
                    !!createBookingProps.value &&
                    isEqual(x.activeDate, createBookingProps.value.activeDate) &&
                    x.serviceJourneyRef === createBookingProps.value.serviceJourneyRef &&
                    x.stopSequence === createBookingProps.value.stopSequence
            );

            isLoading.value = false;
        } catch (e) {
            if (isAxiosError(e)) {
                // in case of AbortController being triggered, do nothing
                if (e.name === 'CanceledError') return;

                if (e.response?.status !== 401) {
                    mainStore.registerError(new CustomError('BookingError', e.response?.data || ''));
                }
            } else {
                console.error(e);
            }

            isLoading.value = false;
        }
    }

    async function loadBooking(id: string) {
        if (!id) return;

        try {
            isLoading.value = true;

            selectedBooking.value = await bookingService.getBooking(id);
        } catch (e) {
            if (isAxiosError(e) && e.response?.status !== 404) {
                mainStore.registerError(new CustomError('BookingError', e.response?.data || ''));
            } else {
                console.error(e);
            }
        } finally {
            isLoading.value = false;
        }
    }

    async function createBooking() {
        if (isBooking.value) return;

        isBooking.value = true;

        const _createBookingProps = toValue(createBookingProps);
        if (!_createBookingProps || !userInfoIsValid.value) return;

        try {
            isLoading.value = true;

            const { countryCode, number } = userInfo.value.phone || {};
            const params: CreateBookingDto = {
                ..._createBookingProps,
                name: userInfo.value.name,
                phoneCountryCode: countryCode,
                phoneNumber: number
            };
            selectedBooking.value = await bookingService.createBooking(params);

            bookings.value.push(toValue(selectedBooking.value));
        } catch (e) {
            if (isAxiosError(e)) {
                // if already exists, load it up
                if (e.response?.status === 302 && e.response.data) {
                    await loadBooking(e.response.data);
                    return;
                }
                if (e.response?.status === 412) {
                    maxBookingExceeded.value = true;
                    return;
                }
                mainStore.registerError(new CustomError('BookingError', e.response?.data || ''));
            } else {
                console.error(e);
            }
        } finally {
            isLoading.value = false;
            isBooking.value = false;
        }
    }

    async function cancelBooking() {
        if (!selectedBooking.value) return;

        try {
            isLoading.value = true;

            await bookingService.cancelBooking(selectedBooking.value.id);

            selectedBooking.value = await bookingService.getBooking(selectedBooking.value.id);
        } catch (e) {
            if (isAxiosError(e)) {
                mainStore.registerError(new CustomError('BookingError', e.response?.data || ''));
            } else {
                console.error(e);
            }
        } finally {
            isLoading.value = false;
        }
    }

    return {
        onlineBookingIsAvailable,
        bookings,
        selectedBooking,
        maxBookingExceeded,
        createBookingProps,
        bookingDeadlineExceeded,
        canBook,
        canCancel,
        stopSignalOrder,
        isLoading,
        isBooking,
        userInfo,
        userInfoIsValid,
        loadBookings,
        loadBooking,
        createBooking,
        cancelBooking,
        loadStopSignalOrder
    };
});
