<template>
    <v-card ref="root" flat color="transparent" class="datetime-picker">
        <v-card-text class="pa-0">
            <v-row dense>
                <!-- timepicker -->
                <v-col cols="6" md="5">
                    <v-menu
                        v-model="timePickerIsVisible"
                        :close-on-content-click="false"
                        location="bottom left"
                        target="parent"
                        :offset="[2, -4]"
                        :min-width="timepickerWidth"
                        :width="timepickerWidth"
                        transition="slide-y-transition"
                    >
                        <template #activator="{ props: activatorProps }">
                            <v-input-wrapper ref="datePickerInput" :focused="timePickerIsVisible">
                                <v-text-field
                                    :model-value="timeValue"
                                    :disabled="disabled"
                                    density="compact"
                                    variant="plain"
                                    hide-details
                                    readonly
                                    bg-color="white"
                                    prepend-inner-icon="mdi-chevron-left"
                                    append-inner-icon="mdi-chevron-right"
                                    class="datetime-picker__timepicker font-weight-regular"
                                    @click:append="showDatePicker"
                                    @click:prepend-inner="decreaseTime"
                                    @click:append-inner="increaseTime"
                                >
                                    <template #append>
                                        <v-icon v-bind="activatorProps" icon="mdi-clock-outline" size="24" color="secondary" />
                                    </template>
                                </v-text-field>
                            </v-input-wrapper>
                        </template>

                        <v-card elevation="4" rounded="lg" class="datetime-picker__dialog">
                            <time-picker
                                v-model="tempTimeValue"
                                color="primary"
                                format="24hr"
                                :min="_minTime"
                                :max="_maxTime"
                                :title="t('common.resources.timePicker.title')"
                                class="w-100"
                            />

                            <v-card-actions class="px-4 pb-4">
                                <v-btn
                                    variant="plain"
                                    color="primary"
                                    :text="t('common.resources.timePicker.cancel')"
                                    @click="hideTimePicker"
                                />
                                <v-spacer />
                                <v-btn
                                    variant="flat"
                                    color="primary"
                                    :text="t('common.resources.timePicker.save')"
                                    @click="setTimeValue(tempTimeValue)"
                                />
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                </v-col>
                <!-- datepicker -->
                <v-col cols="6" md="7">
                    <v-menu
                        v-model="datePickerIsVisible"
                        :close-on-content-click="false"
                        location="bottom right"
                        target="parent"
                        :offset="[2, -4]"
                        :min-width="calendarWidth"
                        :width="calendarWidth"
                        transition="slide-y-transition"
                    >
                        <template #activator="{ props: activatorProps }">
                            <v-input-wrapper ref="datePickerInput" :focused="datePickerIsVisible">
                                <v-text-field
                                    v-bind="activatorProps"
                                    :model-value="format(_localeModelValue, 'dd.MM.yyyy')"
                                    :disabled="disabled"
                                    density="compact"
                                    variant="plain"
                                    hide-details
                                    readonly
                                    bg-color="white"
                                    class="font-weight-regular"
                                    @click:append="showDatePicker"
                                >
                                    <template #append>
                                        <v-icon icon="mdi-calendar" size="24" color="secondary" />
                                    </template>
                                </v-text-field>
                            </v-input-wrapper>
                        </template>

                        <v-card elevation="4" rounded="lg" class="datetime-picker__dialog" :width="calendarWidth">
                            <v-date-picker
                                v-model="dateValue"
                                :min="_minDate"
                                :max="_maxDate"
                                elevation="0"
                                color="primary"
                                hide-header
                                show-adjacent-months
                                @update:model-value="hideDatePicker"
                            >
                            </v-date-picker>
                        </v-card>
                    </v-menu>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, toValue, watch } from 'vue';
import { useElementSize, useVModel } from '@vueuse/core';
import { addMinutes, endOfDay, format, isSameDay, isValid, parse, set, startOfDay } from 'date-fns';
import { toLocalDate, toUtcDate } from '@geta/kolumbus-frontend/composables/DateHelpers';
import { useDisplayHelpers } from '@/features/common/composables';
import type { VCard } from 'vuetify/components';
import { VInputWrapper } from '@/features/common/components';
import { TimePicker } from '../TimePicker';
import { useI18n } from 'vue-i18n';

interface Props {
    modelValue?: Date;
    minDate?: Date | number;
    maxDate?: Date | number;
    disabled?: boolean;
    timeStepInMinutes?: number;
}

const { t } = useI18n();
const props = withDefaults(defineProps<Props>(), { modelValue: () => new Date(), timeStepInMinutes: 30 });
const _modelValue = useVModel(props, 'modelValue');
const _localeModelValue = computed({
    get() {
        // use locale time locally
        return toLocalDate(_modelValue.value);
    },
    set(value) {
        // return UTC value
        _modelValue.value = toUtcDate(value);
    }
});
const _minDate = computed(() => (props.minDate && isValid(props.minDate) ? startOfDay(props.minDate) : undefined));
const _maxDate = computed(() => (props.maxDate && isValid(props.maxDate) ? endOfDay(props.maxDate) : undefined));
const _minTime = computed(() =>
    props.minDate && isValid(props.minDate) && isSameDay(props.minDate, _modelValue.value) ? format(props.minDate, 'HH:mm') : undefined
);
const _maxTime = computed(() =>
    props.maxDate && isValid(props.minDate) && isSameDay(props.maxDate, _modelValue.value) ? format(props.maxDate, 'HH:mm') : undefined
);
const { isMobile } = useDisplayHelpers();

const datePickerIsVisible = ref(false);
const timePickerIsVisible = ref(false);

const root = ref<typeof VCard>();
const rootElement = computed(() => root.value?.$el || null);
const { width: rootElementWidth } = useElementSize(rootElement);
const datePickerInput = ref<HTMLDivElement>();
const { width: datePickerInputWidth } = useElementSize(datePickerInput, undefined, { box: 'border-box' });

const timepickerWidth = computed(() => (isMobile.value ? rootElementWidth.value : undefined));
const calendarWidth = computed(() => (isMobile.value ? rootElementWidth.value : Math.max(360, datePickerInputWidth.value)));

const dateValue = computed({
    get() {
        return startOfDay(_localeModelValue.value);
    },
    set(value) {
        _localeModelValue.value = set(_localeModelValue.value, {
            year: value.getFullYear(),
            month: value.getMonth(),
            date: value.getDate()
        });
    }
});

const timeValue = computed({
    get() {
        return format(_localeModelValue.value, 'HH:mm');
    },
    set(value) {
        // parse string to Date
        const parsedDate = parse(value, 'HH:mm', _localeModelValue.value);
        if (isValid(parsedDate)) {
            _localeModelValue.value = set(_localeModelValue.value, {
                hours: parsedDate.getHours(),
                minutes: parsedDate.getMinutes(),
                seconds: 0,
                milliseconds: 0
            });
        }
    }
});
const tempTimeValue = ref(toValue(timeValue.value));

function setTimeValue(value: string) {
    if (value === timeValue.value) return;

    timeValue.value = value;
    tempTimeValue.value = value;
    hideTimePicker();
}

function showDatePicker() {
    datePickerIsVisible.value = true;
}

function hideDatePicker() {
    datePickerIsVisible.value = false;
}

function hideTimePicker() {
    timePickerIsVisible.value = false;
}

function decreaseTime() {
    _localeModelValue.value = addMinutes(_localeModelValue.value, -(props.timeStepInMinutes || 30));
    nextTick(() => (tempTimeValue.value = toValue(timeValue.value)));
}

function increaseTime() {
    _localeModelValue.value = addMinutes(_localeModelValue.value, props.timeStepInMinutes || 30);
    nextTick(() => (tempTimeValue.value = toValue(timeValue.value)));
}

watch(
    () => props.disabled,
    value => {
        if (value) {
            hideDatePicker();
            hideTimePicker();
        }
    }
);
</script>

<style lang="scss">
@use '@geta/kolumbus-frontend/styles/vuetify' as *;
@use 'vuetify/lib/styles/tools' as *;

.datetime-picker {
    --datepicker-border-color: var(--v-color-grey-lighten-2);

    overflow: visible;

    .v-field {
        --v-field-input-padding-top: 2px;
        --v-field-input-padding-bottom: 2px;
    }

    .v-field__input {
        font-size: calc(16px / $font-size-root) * 1rem;
        height: 40px;

        &[type='time'] {
            text-align: center;
        }
    }

    .v-input__append .v-icon {
        opacity: 1;
    }

    &__dialog {
        border: 1px solid rgb(var(--v-theme-primary));

        .v-date-picker {
            width: 100% !important;
        }
    }

    &__timepicker .v-field__input {
        text-align: center;
    }

    .v-date-picker-month {
        padding: 0 10px 10px;
    }

    .v-date-picker-month__day {
        height: 44px;
        width: 44px;
    }
}
</style>
