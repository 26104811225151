<template>
    <error-boundary :stop-propagation="true">
        <v-app class="tp-app" :class="cssClasses" :style="tpAppStyles">
            <v-layout v-if="mainStore.ready" full-height>
                <site-header
                    v-model:current-language="language"
                    :menu-items="menuItems"
                    :is-logged-in="userStore.isLoggedIn"
                    :user-info="userStore.getProfile"
                    class="tp-app__header"
                />

                <app-drawer v-model="showAppDrawer" :full-height="drawerIsFullHeight" class="tp-app__drawer">
                    <router-view :key="route.path" name="appDrawer" />
                </app-drawer>

                <v-main>
                    <div class="tp-app__banner" :style="{ width: bannerWidth }">
                        <feedback-banner v-if="!isMobile" />
                    </div>
                    <v-container fluid class="pa-0 h-100" style="position: relative">
                        <router-view name="main" />
                    </v-container>
                </v-main>
            </v-layout>

            <v-overlay :model-value="mainStore.isLoading" scrim="white" class="align-center justify-center">
                <v-progress-circular color="primary" indeterminate size="64" />
            </v-overlay>
            <silent-login v-if="config.silentLoginEnabled && userLoaded && !userStore.isLoggedIn" />

            <v-snackbar :model-value="mainStore.isExperimentalMode" timeout="3000">
                <div class="text-body-1">Experimental mode is enabled</div>
            </v-snackbar>
        </v-app>
    </error-boundary>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { computed, getCurrentInstance, onMounted, provide, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useMainStore } from '@/features/common/stores';
import { SiteHeader, useSiteHeader } from '@geta/kolumbus-frontend/components';
import { AppDrawer, SilentLogin, ErrorBoundary, FeedbackBanner } from '@/features/common/components';
import { useDisplayHelpers, useExperimentalModeActivator } from '@/features/common/composables';
import { useUserStore } from './features/authentication';
import { AppProvideKey } from './injectionKeys';
import { createGtm } from 'vue-gtm';
import { useEventListener } from '@vueuse/core';
import { useBookingFeature } from './features/bookings/composables';

useBookingFeature();
const route = useRoute();
const router = useRouter();
const mainStore = useMainStore();
const userStore = useUserStore();
const { menuItems, language, showAppDrawer, config } = storeToRefs(mainStore);
const { height: siteHeaderHeight } = useSiteHeader();
const { isMobile } = useDisplayHelpers();
const experimentalModeActivator = useExperimentalModeActivator();

const userLoaded = ref(false);
const tpAppStyles = computed(() => ({ '--tp-app__header-height': `${siteHeaderHeight.value}px` }));
const cssClasses = ref<string[]>([]);
const currentTime = ref(new Date());
const drawerIsFullHeight = ref(true);

function setDrawerFullHeight(fullHeight: boolean) {
    drawerIsFullHeight.value = fullHeight;
}

const bannerWidth = computed(() => (showAppDrawer.value ? 'calc(100% - 614px)' : '100%'));

provide(AppProvideKey, { currentTime, setDrawerFullHeight });

watch(language, async (value, oldValue) => {
    if (value !== oldValue) {
        try {
            await router.replace({ params: { locale: value }, query: route.query });
        } catch (e) {
            mainStore.registerError(e);
            router.push('/');
        }
    }
});

const instance = getCurrentInstance();

onMounted(async () => {
    // start clock
    setInterval(() => (currentTime.value = new Date()), 1000);

    // init store
    await mainStore.init();
    experimentalModeActivator.init();

    // add event listener to add/remove focused class whenever an input field is focused
    useEventListener(
        document,
        ['focus', 'blur'],
        e => {
            if ((e.target as Element).nodeName !== 'INPUT') return;

            if (e.type === 'focus') {
                cssClasses.value.push('tp-app--has-focused');
            } else if (e.type === 'blur') {
                cssClasses.value = cssClasses.value.filter(x => x !== 'tp-app--has-focused');
            }
        },
        true
    );

    // load user
    if (config.value?.silentLoginEnabled) {
        await userStore.loadUserSilent();
        await userStore.loadUser();
        await userStore.loadProfile(menuItems?.value?.loginLink);

        userLoaded.value = true;
    }

    // set up gtm
    if (instance && config.value?.googleTagManagerConfig?.id) {
        instance.appContext.app.use(createGtm({ id: config.value.googleTagManagerConfig.id }));
    }

    mainStore.ready = true;
});
</script>

<style lang="scss">
.tp-app {
    &__header {
        z-index: 1000;
    }

    &__drawer {
        z-index: 900;
    }

    &__banner {
        position: absolute;
        right: 0;
        top: 70px;
        z-index: 1000;
    }
}

.v-main {
    z-index: 1;
}
</style>
