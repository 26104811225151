<template>
    <div class="search-result-list">
        <slot />
    </div>
</template>

<style lang="scss">
@use '@geta/kolumbus-frontend/styles/vuetify' as *;
@use 'vuetify/lib/styles/tools' as *;

.search-result-list {
    display: flex;
    position: relative;
    flex-direction: column;
    margin: -($spacer * 2) 0;
}
</style>
