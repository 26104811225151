<template>
    <div class="search-page">
        <v-window :model-value="page" :touch="false" class="search-page__inner">
            <v-window-item value="0" :transition="!isMobile">
                <div class="search-page__header">
                    <div class="search-page__header-content pt-md-8 px-md-8">
                        <feedback-banner v-if="isMobile" />
                        <debug-mode-toggle v-if="isDebugModeEnabled" />
                        <search-mode-toggle v-model="mode" class="search-page__header-top" />
                        <div class="search-page__header-bottom pa-4">
                            <router-view name="header" />
                        </div>
                    </div>
                    <v-divider class="mx-4 mt-2 d-md-none" color="secondary" />
                    <div v-show="showHeaderAppend" class="search-page__header-append">
                        <router-view name="headerAppend" />
                    </div>
                </div>

                <div class="search-page__content">
                    <router-view />
                </div>
            </v-window-item>
            <v-window-item value="1" :transition="!isMobile">
                <router-view name="page1" />
            </v-window-item>
        </v-window>
    </div>
</template>

<script setup lang="ts">
import { SearchModeToggle, DebugModeToggle, FeedbackBanner } from '@/features/common/components';
import { useDisplayHelpers, useSearchModeToggle } from '@/features/common/composables';
import { SearchPageProvideKey } from '@/injectionKeys';
import { provide, ref } from 'vue';
import { useMainStore } from '@/features/common/stores';
import { storeToRefs } from 'pinia';

const { mode } = useSearchModeToggle();
const { isMobile } = useDisplayHelpers();

const showHeader = ref(true);
const showHeaderAppend = ref(false);
const page = ref(0);

const { isDebugModeEnabled } = storeToRefs(useMainStore());

function setHeaderVisibility(value: boolean) {
    showHeader.value = value;
}

function setHeaderAppendVisibility(value: boolean) {
    showHeaderAppend.value = value;
}

function setPage(value = 0) {
    page.value = value;
}

provide(SearchPageProvideKey, { setHeaderVisibility, setHeaderAppendVisibility, setPage });
</script>

<style lang="scss">
@use '@geta/kolumbus-frontend/styles/vuetify' as *;
@use 'vuetify/lib/styles/tools' as *;

.search-page {
    --v-search-page-border-color: #e6e6e6;

    width: 100%;
    height: 100%;

    &__inner {
        height: 100%;
    }

    .v-window-item {
        min-height: 100%;

        &--active {
            overflow-y: auto;
        }
    }

    &__header {
        z-index: 1;

        &-top,
        &-bottom {
            position: relative;
        }

        &-bottom {
            z-index: 0;

            @media #{map-get($display-breakpoints, 'md-and-up')} {
                border: 1px solid var(--v-search-page-border-color);
                border-radius: #{map-get($rounded, 'lg')};
                border-top-left-radius: 0;
            }
        }

        &-content,
        &-append {
            position: relative;
        }

        &-content {
            z-index: 2;
        }

        &-append {
            z-index: 1;
        }
    }

    .search-mode-toggle__container {
        position: relative;
        bottom: -1px;
        // offset with margin so we don't end up with empty space at top
        margin-top: -1px;
    }

    &:has(.v-autocomplete--active-menu.place-autocomplete) .search-page__content {
        min-height: 500px;
    }
}

.search-result {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background: var(--v-color-grey-lighten-3);

    .search-result__header {
        background: var(--v-color-white);
    }

    &--mobile {
        background: var(--v-color-white);

        .search-result__header,
        .search-result__footer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: var(--v-color-white);
        }

        .search-result__footer {
            min-height: 80px;
        }

        &.search-result--expanded {
            background: var(--v-color-grey-lighten-3);

            .search-result__content {
                padding-bottom: 100px;

                .tp-app--has-focused & {
                    padding-bottom: 20px;
                }
            }

            .search-result__footer {
                position: fixed;
                width: 100%;
                bottom: 0;
                z-index: 100;

                .tp-app--has-focused & {
                    position: sticky;
                }
            }
        }
    }

    &__back-btn {
        --v-btn-height: 100%;
        font-size: calc(18px / $font-size-root) * 1rem;
        padding: 8px 8px 8px 0;
    }
}
</style>
