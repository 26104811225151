import { BookingContactDto, BookingMethod, TransportMode } from '@/types/webapi';
import { toLocaleDateString, toLocaleTimeString } from '@geta/kolumbus-frontend/composables';
import { isSameDay } from 'date-fns';
import { isDate } from 'lodash-es';
import { useI18n } from 'vue-i18n';

export interface GenerateDescriptionTextParams {
    fromQuay?: string;
    bookingLimitDateTime?: Date;
    bookingMethods?: BookingMethod[];
    bookingContact?: BookingContactDto;
    transportMode?: TransportMode;
    now?: Date;
}

export function useBookingMessages() {
    const { t, locale } = useI18n();

    function generateDescriptionText(params: GenerateDescriptionTextParams) {
        if (!params) return '';

        const { fromQuay, bookingLimitDateTime, bookingMethods = [], bookingContact, now = new Date() } = params;

        let result: string[] = [];
        if (fromQuay && isDate(bookingLimitDateTime)) {
            let bookingLimitDateTimeString = isSameDay(bookingLimitDateTime, now)
                ? t('booking.resources.descriptionTexts.bookingLimitDateTimeToday', [
                      toLocaleTimeString(bookingLimitDateTime, locale.value)
                  ])
                : t('booking.resources.descriptionTexts.bookingLimitDateTimeOther', [
                      `${toLocaleDateString(bookingLimitDateTime, locale.value)}, ${toLocaleTimeString(bookingLimitDateTime, locale.value)}`
                  ]);

            result.push(
                t('booking.resources.descriptionTexts.bookingOriginAndDeadline', {
                    fromQuay,
                    bookingLimitDateTime: bookingLimitDateTimeString
                })
            );
        }

        if (
            (bookingMethods.includes(BookingMethod.CallDriver) || bookingMethods.includes(BookingMethod.CallOffice)) &&
            bookingContact?.phone
        ) {
            result.push(t('booking.resources.descriptionTexts.bookingMethodTel', { contactPhone: bookingContact.phone }));
        }

        return result.join(' ');
    }

    function generateDeadlineExpiredDescriptionText(params: GenerateDescriptionTextParams) {
        if (!params) return '';

        const { fromQuay, bookingLimitDateTime, bookingMethods = [], bookingContact, now = new Date() } = params;
        let result: string[] = [];

        if (fromQuay && isDate(bookingLimitDateTime)) {
            let bookingLimitDateTimeAndOrBookingMethod = isSameDay(bookingLimitDateTime, now)
                ? t('booking.resources.descriptionTexts.bookingLimitDateTimeToday', [
                      toLocaleTimeString(bookingLimitDateTime, locale.value)
                  ])
                : t('booking.resources.descriptionTexts.bookingLimitDateTimeOther', [
                      `${toLocaleDateString(bookingLimitDateTime, locale.value)}, ${toLocaleTimeString(bookingLimitDateTime, locale.value)}`
                  ]);

            if (
                (bookingMethods.includes(BookingMethod.CallDriver) || bookingMethods.includes(BookingMethod.CallOffice)) &&
                bookingContact?.phone
            ) {
                bookingLimitDateTimeAndOrBookingMethod += t('booking.resources.descriptionTexts.bookingDeadlineMethodTel', {
                    contactPhone: bookingContact.phone
                });
            }

            result.push(
                t('booking.resources.descriptionTexts.bookingDeadlineOriginAndDeadline', {
                    fromQuay,
                    bookingLimitDateTimeAndOrBookingMethod
                })
            );
        }

        result.push(`<span class="text-error">${t('booking.resources.descriptionTexts.bookingDeadlineExpired')}</span>`);

        return result.join(' ');
    }

    function generateBookingExistsDescriptionText(params: GenerateDescriptionTextParams) {
        if (!params) return '';

        const { fromQuay, transportMode } = params;
        let result: string[] = [];

        if (fromQuay && transportMode) {
            const transportModeString = getTransportMode(transportMode);

            result.push(
                t('booking.resources.descriptionTexts.bookingAlreadyMadeByOther', { fromQuay, transportMode: transportModeString })
            );
        }

        return result.join(' ');
    }

    function getTransportMode(transportMode: TransportMode) {
        switch (transportMode) {
            case TransportMode.Bus:
                return t('booking.resources.descriptionTexts.bookingTransportModeBus');
            case TransportMode.Water:
                return t('booking.resources.descriptionTexts.bookingTransportModeWater');
            default:
                return '';
        }
    }

    return {
        generateDescriptionText,
        generateDeadlineExpiredDescriptionText,
        generateBookingExistsDescriptionText
    };
}
