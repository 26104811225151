import { nextTick, watch } from 'vue';
import { useBookingStore } from '../stores';
import { useMainStore } from '@/features/common/stores';
import { useTripSearchStore } from '@/features/trips/stores';
import { useDeparturesSearchStore } from '@/features/departures/stores';
import { isDate, isEmpty } from 'lodash-es';
import { isAfter, isEqual } from 'date-fns';
import { DepartureDto, LegDto, NotificationMessageType } from '@/types/webapi';
import { useBookingMessages } from './useBookingMessages';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/features/authentication';

export function useBookingFeature() {
    const mainStore = useMainStore();
    const userStore = useUserStore();
    const bookingStore = useBookingStore();
    const tripSearchStore = useTripSearchStore();
    const departureSearchStore = useDeparturesSearchStore();
    const { stopSignalOrder, maxBookingExceeded, bookings } = storeToRefs(bookingStore);
    const { generateBookingExistsDescriptionText } = useBookingMessages();

    function updateNotificationMessage(legOrDeparture: LegDto | DepartureDto) {
        const bookingNotificationMessage = legOrDeparture.notificationMessages?.find(
            x => x.type === NotificationMessageType.BookingArrangement
        );

        if (!bookingNotificationMessage) return;

        const leg = legOrDeparture as LegDto;
        const departure = legOrDeparture as DepartureDto;
        const fromQuay = leg.from?.quay?.name || departure.quay?.name;
        const transportMode = leg.transitInfo?.transportMode || departure.transportMode;

        bookingNotificationMessage.text = generateBookingExistsDescriptionText({ fromQuay, transportMode });
        bookingNotificationMessage.type = NotificationMessageType.BookingConfirmation;
    }

    async function loadBookingData(legOrDeparture: LegDto | DepartureDto) {
        if (!legOrDeparture.bookingMetadata) return;

        if (userStore.isLoggedIn) {
            // load user bookings
            await bookingStore.loadBookings();
            await nextTick();

            // check if user has valid booking, and update notification messages as necessary
            if (bookings.value.length > 0) {
                const { activeDate, serviceJourneyRef, stopSequence } = legOrDeparture.bookingMetadata;

                // match by activeDate + serviceJourneyRef + stopSequence
                if (
                    bookings.value.find(
                        x =>
                            isDate(activeDate) &&
                            isEqual(x.activeDate, activeDate) &&
                            x.serviceJourneyRef === serviceJourneyRef &&
                            x.stopSequence === stopSequence
                    )
                ) {
                    return updateNotificationMessage(legOrDeparture);
                }
            }
        }

        const { bookingLimitDateTime } = legOrDeparture.bookingMetadata;
        if (!isDate(bookingLimitDateTime) || isAfter(bookingLimitDateTime, mainStore.currentTime)) return;

        await bookingStore.loadStopSignalOrder();
        await nextTick();

        // update notification message if stopSignalOrder exists
        if (!stopSignalOrder.value) return;

        updateNotificationMessage(legOrDeparture);
    }

    const unwatch = watch(
        () => mainStore.ready,
        value => {
            if (!value) return;

            unwatch();

            if (!mainStore.config.bookingConfig?.enabled) return;

            watch(
                () => tripSearchStore.selectedTripPattern,
                async (value, oldValue) => {
                    if (isEmpty(value) || value.id === oldValue?.id) return;

                    stopSignalOrder.value = undefined;
                    maxBookingExceeded.value = false;

                    const bookingLeg = value.legs.find(x => x.bookingMetadata?.onlineBookingIsAvailable);
                    if (!bookingLeg) return;

                    await loadBookingData(bookingLeg);
                },
                { immediate: true }
            );

            watch(
                () => departureSearchStore.selectedDeparture,
                async (value, oldValue) => {
                    if (isEmpty(value?.bookingMetadata) || value.id === oldValue?.id) return;

                    stopSignalOrder.value = undefined;
                    maxBookingExceeded.value = false;

                    await loadBookingData(value);
                },
                { immediate: true }
            );
        }
    );
}
