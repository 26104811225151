import { BookingContactDto, BookingMethod, DepartureDto, LegDto, NotificationMessageType, TransportMode } from '@/types/webapi';
import { isDate, isEmpty, isObject, isUndefined } from 'lodash-es';

export interface BookingParams {
    serviceJourneyRef: string;
    activeDate: Date;
    stopSequence: number;
    bookingLimitDateTime?: Date;
    bookingCancellationLimitDateTime?: Date;
    fromQuay?: string;
    bookingMethods: BookingMethod[];
    bookingContact?: BookingContactDto;
    transportMode?: TransportMode;
    bookingNotificationMessage?: string;
}

export function createBookingParams(departureOrLeg?: DepartureDto | LegDto): BookingParams | undefined {
    if (
        isUndefined(departureOrLeg) ||
        !isObject(departureOrLeg) ||
        isEmpty(departureOrLeg) ||
        !departureOrLeg.bookingMetadata?.onlineBookingIsAvailable
    )
        return undefined;

    const { bookingMetadata, notificationMessages } = departureOrLeg;
    const {
        bookingCancellationLimitDateTime,
        bookingLimitDateTime,
        bookingMethods,
        bookingContact,
        transportMode,
        activeDate,
        serviceJourneyRef,
        stopSequence = -1
    } = bookingMetadata;
    const { quay } = departureOrLeg as Partial<DepartureDto>;
    const { from } = departureOrLeg as Partial<LegDto>;
    const bookingNotificationMessage = notificationMessages?.find(x => x.type === NotificationMessageType.BookingArrangement)?.text;

    if (!isDate(activeDate) || !serviceJourneyRef || stopSequence === -1) return undefined;

    const fromQuay = quay?.name || from?.name;

    return {
        activeDate,
        serviceJourneyRef,
        stopSequence,
        bookingCancellationLimitDateTime,
        bookingLimitDateTime,
        fromQuay,
        bookingMethods,
        bookingContact,
        transportMode,
        bookingNotificationMessage
    };
}
